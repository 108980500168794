<template>
	<div v-if="inventoryItems.length > 0">
		<h4 class="font-weight-black pb-2">Inventory Items</h4>
		<v-simple-table fixed-header>
			<template v-slot:default>
				<thead>
					<tr>
						<th width="60" class="text-left body-2 font-weight-bold bg-light-blue grey--text text--darken-1">Quantity</th>
						<th class="text-left body-2 font-weight-bold bg-light-blue grey--text text--darken-1">Item Name</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in inventoryItems" :key="item.name">
						<td class="font-weight-bold text-center">{{ item.qty }}</td>
						<td>
							<span class="font-weight-medium ml-6">{{ item.itemName }}</span>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "InventoryItemsTable",
	props: {
		inventory: {
			type: Array,
			required: true
		}
	},
	data() {
		return {};
	},

	mounted() {
		let withNames = this.inventory
			.map(item => {
				item["itemName"] = this.getInventoryName(item.rel_inventory_id);
				return item;
			})
			.filter(item => item.itemName.length > 0);
	},
	computed: {
		...mapGetters("CustomerUnique", ["getInventoryName"]),

		inventoryItems() {
			let withNames = this.inventory
				.map(item => {
					item["itemName"] = this.getInventoryName(item.rel_inventory_id);
					return item;
				})
				.filter(item => item.itemName.length > 0);

			// if (!withNames.length) {
			// 	return (withNames = [{ qty: "", itemName: "" }]);
			// }
			return withNames;
		}
	}
};
</script>
<style scoped>
>>> .v-data-table table thead tr th:first-child,
>>> .v-data-table table tbody tr td:first-child {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}
>>> .v-data-table table {
	border: thin solid rgba(0, 0, 0, 0.12);
}
.bg-light-blue {
	background: #e1f5fe !important;
}
</style>