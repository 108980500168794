<template>
	<v-col cols="12" class="mt-6">
		<h4 class="font-weight-black pb-2 text-center text-uppercase">Valuation Coverage</h4>

		<v-card id="scroll-target" class="ma-1 pa-8 overflow-y-auto" elevation="2" max-height="400">
			<div class="text-uppercase">Valuation Coverage</div>
			<div class="py-4">
				<p>The following is a description of the options for protecting your belongings during the moving process.</p>
				<p>Option 1: Released Value-</p>
				<p>
					$0.60 per pound per article up to a maximum of $2,000.00 Service Provider has a maximum liability under State Law for loss or damage to your property while
					being handled at the time of the job. Any damages not documented while the movers are present will not be the responsibility of the mover or Service Provider.
				</p>
			</div>
			<div>
				<p>Option 2: Full Replacement Value-</p>
				<p>Additional Charges Apply for This Option</p>
				<p>
					1.) Repair the article to the extent necessary to restore it to the same condition as when it was received by Service Provider or pay you the cost of such
					repairs.
				</p>
				<p>2.) Replace the article with an article of like kind and quality, or pay you the cost of such a replacement.</p>
			</div>
			<div>
				<p>Any and all claims must be submitted in writing within 15 days of completed move. See Terms & Condition Services for more information.</p>
			</div>
			<div>Exclusions-</div>
			<ul class="ml-3" v-scroll:#scroll-target="onScroll">
				<li>Items of extraordinary value not listed or claimed on the High Value Inventory Form</li>
				<li>Lamps, lamp shades, artwork, pictures, mirrors, artificial plants and statues not packed by Service Provider</li>
				<li>Any marble or glass not crated or boxed by Service Provider</li>
				<li>Items found in boxes not crated, packed or unpacked by Service Provider</li>
				<li>Any items packed and/or unpacked by Service Provider where they (Service Provider) were not the sole transporter</li>
				<li>Any items not put in appropriate boxes or crates, when Service Provider recommended (plasma televisions, grandfather clocks, etc.)</li>
				<li>Mechanical condition of audio/visual or electronic equipment</li>
				<li>Computers and battery operated items in transit or in storage</li>
				<li>Missing hardware not disassembled by Service Provider</li>
				<li>Gold leaf or plaster frames and chandeliers not crated by Service Provider</li>
				<li>Pressboard or particle board furniture</li>
			</ul>
		</v-card>
	</v-col>
</template>

<script>
export default {
	name: "UnderstandingYourEstimate",
	data() {
		return {
			offsetTop: 0
		};
	},
	methods: {
		onScroll(e) {
			this.offsetTop = e.target.scrollTop;

			if (this.offsetTop > 350) {
				this.$emit("enableYourLock", false);
			}
		}
	}
};
</script>
