<template>
	<div>
		<v-row>
			<v-col cols="12" class="mb-8 mt-2">
				<h3 class="pb-1">Customer Info</h3>
				<v-divider></v-divider>
			</v-col>
			<v-row class="px-5">
				<v-col cols="12">
					<h3>Customer Details</h3>
				</v-col>
				<v-col cols="6">
					<v-row class="pb-1">
						<v-col cols="6" class="py-2 font-weight-medium"> Full Name: </v-col>
						<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">{{
							`${getCustomerData != null && getCustomerData.hasOwnProperty("firstName") ? getCustomerData.firstName : ""} ${
								getCustomerData != null && getCustomerData.hasOwnProperty("lastName") ? getCustomerData.lastName : ""
							}`
						}}</v-col>
					</v-row>
					<v-row class="pb-1 mt-0">
						<v-col cols="6" class="py-2 font-weight-medium"> Email: </v-col>
						<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
							{{ getCustomerData != null && getCustomerData.hasOwnProperty("email") ? getCustomerData.email : "" }}
						</v-col>
					</v-row>
					<v-row class="pb-1 mt-0">
						<v-col cols="6" class="py-2 font-weight-medium"> Move Date: </v-col>
						<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right"> </v-col>
					</v-row>
				</v-col>

				<v-col cols="6">
					<v-row class="pb-1">
						<v-col cols="6" class="py-2 font-weight-medium">Phone:</v-col>
						<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
							{{
								getCustomerData != null && getCustomerData.hasOwnProperty("phone")
									? getCustomerData.phone.hasOwnProperty("home")
										? getCustomerData.phone.home
										: ""
									: ""
							}}
						</v-col>
					</v-row>
					<v-row class="pb-1 mt-0">
						<v-col cols="6" class="py-2 font-weight-medium">Mobile Phone:</v-col>
						<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
							{{
								getCustomerData != null && getCustomerData.hasOwnProperty("phone")
									? getCustomerData.phone.hasOwnProperty("mobile")
										? getCustomerData.phone.mobile
										: ""
									: ""
							}}
						</v-col>
					</v-row>
					<v-row class="pb-1 mt-0">
						<v-col cols="6" class="py-2 font-weight-medium">Work Phone:</v-col>
						<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
							{{
								getCustomerData != null && getCustomerData.hasOwnProperty("phone")
									? getCustomerData.phone.hasOwnProperty("work")
										? getCustomerData.phone.work
										: ""
									: ""
							}}
						</v-col>
					</v-row>
				</v-col>

				<!-- DIVIDER -->
				<v-col cols="12" class="mt-4">
					<v-divider></v-divider>
				</v-col>

				<!-- MOVE TO & FROM -->
				<v-col cols="12">
					<v-row>
						<!-- Move From -->
						<v-col cols="6">
							<v-row>
								<v-col cols="12">
									<h3>Move From</h3>
								</v-col>
								<v-col cols="6" class="py-2 font-weight-medium"> Pick-up Address: </v-col>
								<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
									{{
										getLead != null && getLead.hasOwnProperty("originInfo") && getLead.originInfo.hasOwnProperty("pickupAddress")
											? getLead.originInfo.pickupAddress
											: ""
									}}
								</v-col>
								<v-col cols="6" class="py-2 font-weight-medium"> Floor: </v-col>
								<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
									{{ getLead != null && getLead.hasOwnProperty("originInfo") && getLead.originInfo.hasOwnProperty("floor") ? getLead.originInfo.floor : "" }}
								</v-col>
								<v-col cols="6" class="py-2 font-weight-medium"> City: </v-col>
								<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
									{{
										getLead != null && getLead.hasOwnProperty("originInfo") && getLead.originInfo.hasOwnProperty("fromCity")
											? getCity(getLead.originInfo.fromCity)
											: ""
									}}
								</v-col>
								<v-col cols="6" class="py-2 font-weight-medium"> Zipcode: </v-col>
								<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
									{{
										getLead != null && getLead.hasOwnProperty("originInfo") && getLead.originInfo.hasOwnProperty("fromZipcode")
											? getLead.originInfo.fromZipcode
											: ""
									}}
								</v-col>
								<v-col cols="6" class="py-2 font-weight-medium"> State: </v-col>
								<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
									{{
										getLead != null && getLead.hasOwnProperty("originInfo") && getLead.originInfo.hasOwnProperty("fromState")
											? getState(getLead.originInfo.fromState)
											: ""
									}}
								</v-col>
							</v-row>
						</v-col>
						<!-- Move to -->
						<v-col cols="6">
							<v-row>
								<v-col cols="12">
									<h3>Move To</h3>
								</v-col>
								<v-col cols="6" class="py-2 font-weight-medium"> Pick-up Address: </v-col>
								<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
									{{
										getLead != null && getLead.hasOwnProperty("destinationInfo") && getLead.destinationInfo.hasOwnProperty("destinationAddress")
											? getLead.destinationInfo.destinationAddress
											: ""
									}}
								</v-col>
								<v-col cols="6" class="py-2 font-weight-medium"> Floor: </v-col>
								<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
									{{
										getLead != null && getLead.hasOwnProperty("destinationInfo") && getLead.destinationInfo.hasOwnProperty("destinationFloor")
											? getLead.destinationInfo.destinationFloor
											: ""
									}}
								</v-col>
								<v-col cols="6" class="py-2 font-weight-medium"> City: </v-col>
								<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
									{{
										getLead != null && getLead.hasOwnProperty("destinationInfo") && getLead.destinationInfo.hasOwnProperty("destinationCity")
											? getCity(getLead.destinationInfo.destinationCity)
											: ""
									}}
								</v-col>
								<v-col cols="6" class="py-2 font-weight-medium"> Zipcode: </v-col>
								<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
									{{
										getLead != null && getLead.hasOwnProperty("destinationInfo") && getLead.destinationInfo.hasOwnProperty("destinationZipcode")
											? getLead.destinationInfo.destinationZipcode
											: ""
									}}
								</v-col>
								<v-col cols="6" class="py-2 font-weight-medium"> State: </v-col>
								<v-col cols="5" class="py-2 grey--text text--darken-2 pl-2 font-weight-medium text-right">
									{{
										getLead != null && getLead.hasOwnProperty("destinationInfo") && getLead.destinationInfo.hasOwnProperty("destinationState")
											? getState(getLead.destinationInfo.destinationState)
											: ""
									}}
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>

				<!-- Inventory Items -->
				<v-col cols="12" class="mt-8">
					<!-- <InventoryItemsTable :inventory="getLead != null && getLead.hasOwnProperty('inventory') ? getLead.inventory : []"></InventoryItemsTable> -->
				</v-col>

				<!-- Moving Supplies Items -->
				<v-col cols="12" class="mt-8">
					<MovingSuppliesItemsTable></MovingSuppliesItemsTable>
				</v-col>

				<!-- comments -->
				<v-col cols="12" class="mt-6">
					<v-textarea disabled outlined background-color="grey lighten-4" placeholder="No Comments" rows="3"></v-textarea>
				</v-col>
			</v-row>
		</v-row>
	</div>
</template>

<script>
import MovingSuppliesItemsTable from "Components/clientsBilling/commonOnTabs/movingSuppliesItemsTable";
import { mapGetters, mapActions } from "vuex";

import billOfLading from "@/mixins/billOfLading";
export default {
	name: "BillingTabFirst",
	components: {
		MovingSuppliesItemsTable
	},
	mixins: [billOfLading],
	data() {
		return {
			headers: [
				{
					text: "Item Name",
					align: "start",
					class: "body-1 font-weight-bold",
					value: "name",
					sortable: false
				},
				{ text: "Quantity", sortable: false, class: "body-1 font-weight-bold", value: "quantity" }
			],
			desserts: [
				{
					name: "Bed - Box Spring",
					quantity: 1
				},
				{
					name: "Dryer",
					quantity: 1
				},
				{
					name: "Sofa, Sectional - 5 Piece",
					quantity: 1
				},
				{
					name: "Table, Dinnett",
					quantity: 1
				},
				{
					name: "Dining Table, Large",
					quantity: 1
				}
			]
		};
	},
	watch: {
		getLead: {
			immediate: true,
			deep: true,
			async handler(val) {
				// if (val !== null && val.hasOwnProperty("stripe") && !val.stripe.hasOwnProperty("amountDeposit") && !val.stripe.hasOwnProperty("totalBilling")) {
				// if (val !== null && val.hasOwnProperty("stripe") && val.stripe.hasOwnProperty("amountDeposit")) {
				// 	// await this.updateInitialDeposit();
				// }
			}
		}
	},
	computed: {
		...mapGetters("CustomerUnique", ["getLead", "getCustomerData"])
	},
	async mounted() {
		// if (this.getLead !== null && this.getLead.hasOwnProperty("stripe") && this.getLead.stripe.hasOwnProperty("amountDeposit")) {
		// 	// await this.updateInitialDeposit();
		// }
	},
	methods: {
		...mapActions("CustomerUnique", ["updateLead"]),

		async updateInitialDeposit() {
			let authKey = this.$route.params.authKey;
			let crsKey = this.$route.params.crsKey;
			let id = this.$route.params.leadId;
			let customerId = this.$route.params.customerId;

			let stripe = { ...this.getLead.stripe };

			stripe["amountDeposit"] = this.getDepositNeed;
			stripe["remainingBalance"] = this.getSumEstimates;
			stripe["totalBilling"] = this.getSumEstimates;
			stripe["paymentStatus"] = 0;
			let params = {
				id,
				authKey,
				crsKey,
				leadId: id,
				customerId,
				status: 3,
				stripe
			};

			let update = await this.updateLead(params);
			// console.log(update, "inside function mo returned sya");
		}
	}
};
</script>

<style scoped>
>>> .v-data-table table thead tr th:first-child,
>>> .v-data-table table tbody tr td:first-child {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}
>>> .v-data-table table {
	border: thin solid rgba(0, 0, 0, 0.12);
}
</style>