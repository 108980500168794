<template>
	<v-row>
		<v-col cols="12">
			<h3 class="font-weight-black pb-1">Estimates</h3>
			<v-divider></v-divider>
		</v-col>
		<v-row class="px-5 mt-5">
			<v-col cols="6">
				<h4 class="font-weight-black mb-6">Customer Details</h4>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Full Name:</v-col>
					<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getCustomerData != null && getCustomerData.hasOwnProperty("firstName") ? getCustomerData.firstName : "" }}
						{{ getCustomerData != null && getCustomerData.hasOwnProperty("lastName") ? getCustomerData.lastName : "" }}
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Phone:</v-col>
					<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getCustomerData != null && getCustomerData.hasOwnProperty("phone") && getCustomerData.phone.hasOwnProperty("home") ? getCustomerData.phone.home : "" }}
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Mobile Phone:</v-col>
					<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getCustomerData != null && getCustomerData.hasOwnProperty("phone") && getCustomerData.phone.hasOwnProperty("mobile") ? getCustomerData.phone.mobile : ""
						}}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Work Phone:</v-col>
					<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getCustomerData != null && getCustomerData.hasOwnProperty("phone") && getCustomerData.phone.hasOwnProperty("work") ? getCustomerData.phone.work : "" }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Email:</v-col>
					<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getCustomerData != null && getCustomerData.hasOwnProperty("email") ? getCustomerData.email : "" }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="11">
						<v-divider></v-divider>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="6">
				<h4 class="font-weight-black mb-6">Relocation Details</h4>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Estimate Created On:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getLead != null && getLead.hasOwnProperty("contactInfo") && getLead.contactInfo.hasOwnProperty("approxMoveDate")
								? getDateOnly(getLead.contactInfo.approxMoveDate)
								: ""
						}}
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Pickup Date:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getLead != null && getLead.hasOwnProperty("contactInfo") && getLead.contactInfo.hasOwnProperty("estimatedDate")
								? getDateOnly(getLead.contactInfo.estimatedDate)
								: ""
						}}
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Appox Weight:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getLead != null && getLead.hasOwnProperty("contactInfo") && getLead.contactInfo.hasOwnProperty("approxWeight")
								? `${getLead.contactInfo.approxWeight} CF (8000 LBS)`
								: ""
						}}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Rate:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getLead != null && getLead.hasOwnProperty("localInfo") && getLead.localInfo.hasOwnProperty("rate") ? getLead.localInfo.rate : "" }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Parking:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getLead != null && getLead.hasOwnProperty("contactInfo") && getLead.contactInfo.hasOwnProperty("parking") ? getLead.contactInfo.parking : "" }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Additional Stop:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getLead != null && getLead.hasOwnProperty("contactInfo") && getLead.contactInfo.hasOwnProperty("additionalStop")
								? getLead.contactInfo.additionalStop
								: ""
						}}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Men Needed:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getLead != null && getLead.hasOwnProperty("localInfo") && getLead.localInfo.hasOwnProperty("menNeeded") ? getLead.localInfo.menNeeded : "" }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Hours Minimum:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getLead != null && getLead.hasOwnProperty("localInfo") && getLead.localInfo.hasOwnProperty("hoursNeeded") ? getLead.localInfo.hoursNeeded : "" }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-divider></v-divider>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-col cols="12">
			<!-- <v-divider></v-divider> -->
		</v-col>
		<v-row class="px-5">
			<v-col cols="6">
				<h4 class="font-weight-black mb-6">Move From</h4>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Pick-up Addres:</v-col>
					<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getLead != null && getLead.hasOwnProperty("originInfo") && getLead.originInfo.hasOwnProperty("pickupAddress") ? getLead.originInfo.pickupAddress : "" }}
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Floor:</v-col>
					<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getLead != null && getLead.hasOwnProperty("originInfo") && getLead.originInfo.hasOwnProperty("floor") ? getLead.originInfo.floor : "" }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">City:</v-col>
					<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{ getLead != null && getLead.hasOwnProperty("originInfo") && getLead.originInfo.hasOwnProperty("fromCity") ? getCity(getLead.originInfo.fromCity) : "" }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Zipcode:</v-col>
					<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">{{
						getLead != null && getLead.hasOwnProperty("originInfo") && getLead.originInfo.hasOwnProperty("fromZipcode") ? getLead.originInfo.fromZipcode : ""
					}}</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">State:</v-col>
					<v-col cols="5" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getLead != null && getLead.hasOwnProperty("originInfo") && getLead.originInfo.hasOwnProperty("fromState") ? getState(getLead.originInfo.fromState) : ""
						}}
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="6">
				<h4 class="font-weight-black mb-6">Moving To</h4>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Pick-up Addres:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getLead != null && getLead.hasOwnProperty("destinationInfo") && getLead.destinationInfo.hasOwnProperty("destinationAddress")
								? getLead.destinationInfo.destinationAddress
								: ""
						}}
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Floor:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getLead != null && getLead.hasOwnProperty("destinationInfo") && getLead.destinationInfo.hasOwnProperty("destinationFloor")
								? getLead.destinationInfo.destinationFloor
								: ""
						}}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">City:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getLead != null && getLead.hasOwnProperty("destinationInfo") && getLead.destinationInfo.hasOwnProperty("destinationCity")
								? getCity(getLead.destinationInfo.destinationCity)
								: ""
						}}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">Zipcode:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getLead != null && getLead.hasOwnProperty("destinationInfo") && getLead.destinationInfo.hasOwnProperty("destinationZipcode")
								? getLead.destinationInfo.destinationZipcode
								: ""
						}}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="py-1 font-weight-medium">State:</v-col>
					<v-col cols="6" class="py-1 grey--text text--darken-2 pl-2 font-weight-medium text-right">
						{{
							getLead != null && getLead.hasOwnProperty("destinationInfo") && getLead.destinationInfo.hasOwnProperty("destinationState")
								? getState(getLead.destinationInfo.destinationState)
								: ""
						}}
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-col cols="12" class="mt-6">
			<v-divider></v-divider>
		</v-col>
		<v-col cols="12" class="mt-4">
			<span class="blue lighten-2 white--text px-4 py-2 font-weight-medium rounded body-2"> Binding Estimate </span>
		</v-col>

		<!-- Estimates Table -->
		<estimates-table></estimates-table>

		<!-- Inventory Items Table -->
		<v-col cols="12" class="mt-6">
			<InventoryItemsTable :inventory="getLead != null && getLead.hasOwnProperty('inventory') ? getLead.inventory : []"></InventoryItemsTable>
		</v-col>

		<!-- Moving Supplies table -->
		<v-col cols="12" class="mt-6">
			<MovingSuppliesItemsTable></MovingSuppliesItemsTable>
		</v-col>

		<UnderstandingYourStatement @enableYourLock="(status) => (enableConfirmed = status)"></UnderstandingYourStatement>

		<v-col cols="12" class="mt-6">
			<v-row class="align-end mt-8">
				<v-col cols="4" class="text-center">
					<v-text-field id="customer-name" disabled class="text-center-important" hide-details="" :value="fullName"></v-text-field>
					<label for="customer-name" class="font-weight-bold">Customer Name</label>
				</v-col>
				<v-col cols="4" class="text-center">
					<template v-if="checkConfirnSign">
						<v-img :src="getLead.customerAction.confirmedEstimatesSignature" max-height="125" contain class=""></v-img>
						<v-divider></v-divider>
					</template>
					<template v-else>
						<v-text-field id="customer-confirmed-signature" disabled hide-details=""></v-text-field>
					</template>
					<label for="customer-confirmed-signature" class="font-weight-bold">Customer Signature</label>
				</v-col>
				<v-col cols="4" class="text-center">
					<v-text-field id="customer-confirmed-date" class="text-center-important" disabled hide-details="" :value="getConfirmedDate"></v-text-field>

					<label for="customer-confirmed-date" class="font-weight-bold">Date</label>
				</v-col>
			</v-row>
			<v-row class="mb-8">
				<v-col cols="12" class="text-center">
					<v-btn
						@click="confirmedEstimate"
						:disabled="enableConfirmed"
						color="blue darken-1"
						height="45"
						:dark="!enableConfirmed"
						depressed
						class="font-weight-bold text-capitalize"
					>
						<template v-if="enableConfirmed && submitData">
							<v-progress-circular indeterminate size="22" class="mr-2" color="blue"></v-progress-circular>
							Please wait...
						</template>
						<template v-else> Sign and Confirmed Here! </template>
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
		<customer-sign-and-confirmed ref="signAndConfirmed"> Add your signature here to confirmed the estimates! </customer-sign-and-confirmed>
	</v-row>
</template>

<script>
import UnderstandingYourStatement from "./understandingYourEstimate";
import InventoryItemsTable from "Components/clientsBilling/commonOnTabs/inventoryItemsTable";
import MovingSuppliesItemsTable from "Components/clientsBilling/commonOnTabs/movingSuppliesItemsTable";
import EstimatesTable from "Components/clientsBilling/commonOnTabs/estimatesTable";
import CustomerSignAndConfirmed from "Components/Dialogs/CustomerSignAndConfirmed";
import { mapGetters, mapActions } from "vuex";
export default {
	name: "BillingTabSecond",
	components: {
		UnderstandingYourStatement,
		InventoryItemsTable,
		MovingSuppliesItemsTable,
		EstimatesTable,
		CustomerSignAndConfirmed
	},
	data() {
		return {
			enableConfirmed: true,
			submitData: false
		};
	},
	methods: {
		...mapActions("CustomerUnique", ["updateLead"]),
		...mapActions("Notification", ["successNotice"]),
		confirmedEstimate() {
			this.$refs.signAndConfirmed.open().then(async res => {
				if (!res.signed) {
					return;
				}

				// Trigger loader icon on submit btn, disabling to prevent submitting multiple times
				this.enableConfirmed = true;
				this.submitData = true;

				//do update the lead status to 3
				let authKey = this.$route.params.authKey;
				let crsKey = this.$route.params.crsKey;
				let id = this.$route.params.leadId;
				let customerId = this.$route.params.customerId;
				// let customerAction = this.getLead.customerAction;
				let customerAction = { ...this.getLead.customerAction };

				customerAction["confirmedDateOfEstimates"] = Date.now();
				customerAction["confirmedEstimatesSignature"] = res.svg;
				let params = {
					id,
					authKey,
					crsKey,
					leadId: id,
					customerId,
					status: 3,
					customerAction
				};

				let update = await this.updateLead(params);

				// display success notification
				if (update.status == 200) {
					await this.successNotice({
						title: "Confirmed Estimates!",
						description: "Successfully signed and confirmed estimates.."
					});
				}

				// remove submit btn loader
				this.enableConfirmed = false;
				this.submitData = false;

				// unlock services and payments tab
				this.$emit("tabUnlock", false);
			});
		}
	},
	computed: {
		...mapGetters("CustomerUnique", ["getLead", "getCustomerData"]),
		checkConfirnSign() {
			return (
				this.getLead.hasOwnProperty("customerAction") &&
				this.getLead.customerAction.hasOwnProperty("confirmedEstimatesSignature") &&
				this.getLead.customerAction.confirmedEstimatesSignature.length > 0
			);
		},
		fullName() {
			let first = this.getCustomerData != null && this.getCustomerData.hasOwnProperty("firstName") ? this.getCustomerData.firstName : "";
			let last = this.getCustomerData != null && this.getCustomerData.hasOwnProperty("lastName") ? this.getCustomerData.lastName : "";
			return `${first} ${last}`;
		},
		getConfirmedDate() {
			let lead = { ...this.getLead };
			return lead.hasOwnProperty("customerAction") && lead.customerAction.hasOwnProperty("confirmedDateOfEstimates")
				? this.getDateOnly(lead.customerAction.confirmedDateOfEstimates)
				: this.getDateOnly(Date.now());
		}
	},
	created() {
		// check if customer already sign and confirmed the estimates
		if (this.checkConfirnSign) {
			this.enableConfirmed = false;
		}
	}
};
</script>

<style scoped>
>>> .v-data-table table thead tr th:first-child,
>>> .v-data-table table tbody tr td:first-child {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}
>>> .v-data-table table {
	border: thin solid rgba(0, 0, 0, 0.12);
}
>>> .text-center-important .v-input__control .v-input__slot .v-text-field__slot input {
	text-align: center !important;
}
</style>