import { render, staticRenderFns } from "./tab1.vue?vue&type=template&id=25262a1d&scoped=true&"
import script from "./tab1.vue?vue&type=script&lang=js&"
export * from "./tab1.vue?vue&type=script&lang=js&"
import style0 from "./tab1.vue?vue&type=style&index=0&id=25262a1d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25262a1d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VDivider,VRow,VTextarea})
