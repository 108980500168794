<template>
	<div v-if="movingSupplies.length > 0">
		<h4 class="font-weight-black pb-2">Moving Supplies Items</h4>
		<v-simple-table fixed-header>
			<template v-slot:default>
				<thead>
					<tr>
						<th width="60" class="text-left body-2 font-weight-bold bg-light-blue grey--text text--darken-1">Quantity</th>
						<th class="text-left body-2 font-weight-bold bg-light-blue grey--text text--darken-1">Item Name</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in movingSupplies" :key="item.name">
						<td class="font-weight-bold text-center">{{ item.qty }}</td>
						<td>
							<span class="font-weight-medium ml-6">{{ item.supplyName }}</span>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "MovingSuppliesItemsTable",
	data() {
		return {};
	},
	computed: {
		...mapGetters("CustomerUnique", ["getLead", "getCustomerData"]),

		movingSupplies() {
			if (
				this.getLead != null &&
				this.getLead.hasOwnProperty("movingSupplies") &&
				this.getLead.movingSupplies.hasOwnProperty("supplies") &&
				this.getLead.movingSupplies.supplies.length > 0
			) {
				return this.getLead.movingSupplies.supplies.filter(item => item.qty != null);
			}
			return [];
		}
	}
};
</script>

<style scoped>
>>> .v-data-table table thead tr th:first-child,
>>> .v-data-table table tbody tr td:first-child {
	border-right: thin solid rgba(0, 0, 0, 0.12);
}
>>> .v-data-table table {
	border: thin solid rgba(0, 0, 0, 0.12);
}
.bg-light-blue {
	background: #e1f5fe !important;
}
</style>